/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 *
 * exam management helper
 *
 */
import { isNullOrUndefined } from 'util';
import { ExamMode } from 'enumerations/ExamMode';
import { OptionState } from 'enumerations/OptionState';
import { currentTimeAsISO } from '../timeHelper';
import { persistenceState } from './common/persistenceState';
import {
  createArrayIfNecessary,
  getArrayItemByKey,
} from './common/arrayHelper';
import { getSection, setInstanceChanged } from './common/instanceHelper';
import {
  setAttributeIfExists,
  setAttributesIfExist,
} from './common/attributeHelper';
import { refreshSummary } from './common/summaryHelper';
import { requestSaveToLocal, persistNow } from './common/storageHelper';
import { getInstanceById, getInstanceRepo } from './common/repoHelper';
import { ModuleType } from '../../enumerations/ModuleType';

/**
 * Sets the section isCompleted flag
 * @param {string} sectionId the section to complete
 */
export function logSectionComplete(sectionId) {
  const section = getSection(sectionId);
  if (!isNullOrUndefined(section)) {
    section.isCompleted = true;
  }
  const now = currentTimeAsISO();
  if (!isNullOrUndefined(section)) {
    section.endTime = now;
  }
  logModulePageState();
  setInstanceChanged(section, 'isCompleted');
  verifyTotalAnsweredForSection(section);
  refreshSummary();
  return persistenceState.repo;
}
/**
 * Sets the module isCompleted flag
 */
export function logModuleComplete() {
  const now = currentTimeAsISO();
  const instanceRepo = getInstanceRepo();
  if (instanceRepo.state) {
    instanceRepo.state.endTime = now;
    instanceRepo.state.isCompleted = true;
    verifyTotalAnsweredForAllSection(instanceRepo.state);
    setInstanceChanged(null, 'isCompleted');
    refreshSummary();
  }
  return persistenceState.repo;
}

/**
 * Sets the isContentExposed flag in state and summary in the instance to true
 * @returns {void}
 */
export function setInstanceExposed() {
  const instance = getInstanceRepo();
  if (instance && instance.state) {
    // only set exposed one time
    if (
      (instance.state.isCompleted === undefined ||
        instance.state.isCompleted === false) &&
      (instance.state.isContentExposed === undefined ||
        instance.state.isContentExposed === false)
    ) {
      instance.state.isContentExposed = true;

      setInstanceChanged(null, 'isContentExposed');
      refreshSummary();
      persistNow();
    }
  }
}

/**
 * Sets the break time remaining in state in the instance
 * @returns {void}
 */
export function setInstanceBreakTimeCompleted(timeRemaining) {
  const instance = getInstanceRepo();
  if (instance && instance.state) {
    instance.state.breakTimeSpent = timeRemaining;
    setInstanceChanged(null, 'breakTimeSpent');
    refreshSummary();
    persistNow();
  }
}

/**
 * Log module exam settings
 *
 * @param {object} The module
 */
export function logModuleExamSettings(module) {
  if (!isNullOrUndefined(module)) {
    const { state } = getInstanceRepo();
    let changed = false;
    if (isNullOrUndefined(state.examMode)) {
      state.examMode = !isNullOrUndefined(module.examMode)
        ? module.examMode
        : ExamMode.SelfPaced;
      changed = true;
    }
    if (isNullOrUndefined(state.customTime)) {
      state.customTime = !isNullOrUndefined(module.customTime)
        ? module.customTime
        : 35;
      changed = true;
    }
    if (changed) {
      setInstanceChanged(null, 'examSettings');
      persistNow();
    }
  }
  return persistenceState.repo;
}
/**
 *Log flex exam module page state
 *
 * @param {module}  module The module object
 */
export function logModulePageState(currentPage) {
  const { state } = getInstanceRepo();
  if (!isNullOrUndefined(state)) {
    state.currentPage = currentPage;
    requestSaveToLocal();
  }
  return persistenceState.repo;
}
/**
 * Log flex exam pause state
 *
 * @param {bool} isPaused The section isPaused or not
 * @param {string} pausedOn The section pausedOn page
 */
export function logFlexExamPause(isPaused, pausedOn) {
  const instanceRepo = getInstanceRepo();
  if (!isNullOrUndefined(instanceRepo)) {
    const { state } = instanceRepo;
    if (!isNullOrUndefined(state)) {
      state.isFlexPaused = isPaused;
      if (isPaused) {
        state.flexPausedOn = pausedOn;
      } else {
        delete state.flexPausedOn;
      }
      setInstanceChanged(null, 'flexExamPause');
    }
  }
  return persistenceState.repo;
}
/**
 * Log section pause details
 * @param {bool} isSectionPaused The section is paused
 * @param {pageNem} pausedPageName The paused section on Question or directions
 * @param {*} sectionId The paused section id
 * @param {*} questionNumber The paused section question number
 *
 */
export function logSectionPause(
  isSectionPaused,
  pausedPageName,
  sectionId,
  questionNumber,
  shouldNeverShowPausePopup,
) {
  const { state } = getInstanceRepo();

  if (!isNullOrUndefined(state) && isSectionPaused) {
    state.isSectionPaused = isSectionPaused;
    state.pausedOnPage = pausedPageName;
    state.pausedSectionId = sectionId;
    state.pausedQuestionIndex = questionNumber;
    state.shouldNeverShowPausePopup = shouldNeverShowPausePopup;

    setInstanceChanged(null, 'isSectionPaused');
    persistNow();
  }

  return persistenceState.repo;
}
/**
 * Log section pause details
 * @param {object} module The module
 *
 */
export function logUndoSectionPause(module) {
  const { state } = getInstanceRepo();

  if (!isNullOrUndefined(state)) {
    state.isSectionPaused = false;
    state.pausedOnPage = null;
    state.pausedSectionId = null;
    state.pausedQuestionIndex = null;
  }

  module.isSectionPaused = false;
  module.pausedOnPage = null;
  module.pausedSectionId = null;
  module.pausedQuestionIndex = null;

  setInstanceChanged(null, 'isSectionPaused');
  persistNow();

  return persistenceState.repo;
}
export function setInstanceTerminated(isTerminated = true) {
  const instance = getInstanceRepo();
  if (instance && instance.state) {
    instance.state.isTerminated = isTerminated;
    instance.state.terminatedTime = currentTimeAsISO();
  }
  setInstanceChanged(null, 'isTerminated');
  refreshSummary();
  persistNow();
}
/**
 * sets a property on a section
 * @param {string} sectionId the section id
 * @param {string} name the name of the property to set
 * @param value the value of the property to set
 */
export function setSectionValue(sectionId, name, value) {
  let changed = false;
  const section = getSection(sectionId);
  if (!isNullOrUndefined(section)) {
    if (section[name] !== value) {
      section[name] = value;
      changed = true;
    }
  }
  return changed;
}
/**
 * gets a specified item in a section
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 */
function getSectionItem(sectionId, itemNumber) {
  const section = getSection(sectionId);
  createArrayIfNecessary(section, 'items');
  const item =
    !isNullOrUndefined(section) && !isNullOrUndefined(section.items)
      ? getArrayItemByKey(section.items, 'itemNumber', itemNumber)
      : null;
  return item;
}
/**
 * gets a specified answer option in a section item
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 * @param {string} optionLetter the key for the answer option
 */
export function getAnswerOption(sectionId, itemNumber, optionLetter) {
  const item = getSectionItem(sectionId, itemNumber);
  createArrayIfNecessary(item, 'answerOptions');
  const answerOption = getArrayItemByKey(
    item.answerOptions,
    'letter',
    optionLetter,
  );
  return answerOption;
}
/**
 * sets the option mode for an answer item
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 * @param {string} optionLetter the key for the answer option
 * @param {number} optionMode the option mode to set
 */
export function setItemOptionMode(
  sectionId,
  itemNumber,
  optionLetter,
  optionMode,
) {
  const answerOption = getAnswerOption(sectionId, itemNumber, optionLetter);
  answerOption.optionMode = optionMode;
}
/**
 * sets the option mode for an answer item
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 * @param {array} answerOptions the answer option values to set
 */
export function setItemOptionState(sectionId, itemNumber, answerOptions) {
  const item = getSectionItem(sectionId, itemNumber);
  createArrayIfNecessary(item, 'answerOptions');
  let i = 0;
  let isAnswered = false;
  for (i = 0; i < answerOptions.length; i += 1) {
    const inputOption = answerOptions[i];
    const answerOption = getArrayItemByKey(
      item?.answerOptions,
      'letter',
      inputOption.letter,
    );
    answerOption.optionState = inputOption.optionState;
    if (!isNullOrUndefined(inputOption.optionState)) {
      if (inputOption.optionState === OptionState.Selected) {
        if (!item?.answer) {
          incrementTotalAnswered(item, sectionId);
        }
        if (item !== null) {
          item.answer = inputOption.letter;
          isAnswered = true;
        } else {
          console.log(
            `Unable to set Item Option State :: Item not found for sectionId: ${sectionId} with itemNumber: ${itemNumber}`,
          );
        }
      }
    }
  }
  item.isAnswered = isAnswered;
  if (!isAnswered && !isNullOrUndefined(item.answer)) {
    delete item.answer;
    decrementTotalAnswered(item, sectionId);
  }
  refreshSummary();
}

/**
 * sets the time spent on an item
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 * @param {array} timeSpent the time spent on the item
 */
export function setTimeSpentOnItem(sectionId, itemNumber, timeSpent) {
  const item = getSectionItem(sectionId, itemNumber);
  if (item) {
    item.totalTimeTaken = (item.totalTimeTaken ?? 0) + timeSpent;
    refreshSummary();
  }
}

/**
 * increment the total answered count across each section
 * @param {string} sectionId the sectionId
 * @param {number} itemNumber the item
 */
function incrementTotalAnswered(item, sectionId) {
  const instance = getInstanceRepo();
  const currentSection = getSection(sectionId);
  const isWritingSection =
    instance?.state?.moduleType === ModuleType.Writing ||
    instance?.state?.moduleType === ModuleType.WritingWithPerspectives;
  let totalAnswered = 0;

  if (isWritingSection) {
    if (
      !isNullOrUndefined(instance?.state?.sections[0]?.items[0]?.writingText)
    ) {
      totalAnswered += 1;
    }
  } else if (!isNullOrUndefined(item)) {
    totalAnswered += 1;
  }

  if (instance.state.totalAnswered && !isWritingSection) {
    instance.state.totalAnswered += totalAnswered;
  } else {
    instance.state.totalAnswered = 1;
  }

  if (currentSection.totalAnswered) {
    currentSection.totalAnswered += totalAnswered;
  } else {
    currentSection.totalAnswered = 1;
  }

  refreshSummary();
}

/**
 * decrement the total answered count across each section
 * @param {string} sectionId the sectionId
 * @param {number} itemNumber the item
 */
function decrementTotalAnswered(item, sectionId) {
  const instance = getInstanceRepo();
  const currentSection = getSection(sectionId);
  const isWritingSection =
    instance?.state?.moduleType === ModuleType.Writing ||
    instance?.state?.moduleType === ModuleType.WritingWithPerspectives;

  if (!item.isAnswered && !isWritingSection) {
    instance.state.totalAnswered -= 1;
    currentSection.totalAnswered -= 1;
  } else if (
    isWritingSection &&
    instance?.state?.sections[0]?.items[0]?.writingText === ''
  ) {
    instance.state.totalAnswered -= 1;
    currentSection.totalAnswered -= 1;
  }

  refreshSummary();
}

/**
 * verify if the totalAnswered is accurate from all section
 * @param {string} state the module to verify
 * @returns void
 */
function verifyTotalAnsweredForAllSection(state) {
  let totalAnswered = 0;
  state?.sections?.forEach(item => {
    if (item.formId !== 'Section 2') {
      totalAnswered += item?.totalAnswered;
    }
  });
  if (
    totalAnswered &&
    state?.totalAnswered &&
    state?.totalAnswered !== totalAnswered
  ) {
    state.totalAnswered = totalAnswered;
  }
}
/**
 * verify if the totalAnswered is accurate as per each item's isAnswered value
 * @param {string} section the section to verify
 * @returns void
 */
function verifyTotalAnsweredForSection(section) {
  const itemsWithAnswer = section.items?.filter(i => {
    if (i.writingText && i.writingText.length > 0) return true;
    return i.isAnswered;
  });

  if (section.totalAnswered !== itemsWithAnswer?.length) {
    section.totalAnswered = itemsWithAnswer.length;
  }
}

/**
 * sets the option mode for an answer item
 * @param {string} sectionId the section id
 * @param {number} itemNumber the item number
 * @param {array} answerOptions the answer option values to set
 */
export function setItemWritingText(sectionId, itemNumber, writingText) {
  const item = getSectionItem(sectionId, itemNumber);
  if (item !== null) {
    item.writingText = writingText;
    refreshSummary();
  } else {
    console.log(
      `Item not found for sectionId: ${sectionId} with itemNumber: ${itemNumber}`,
    );
  }
}
export function setNotesText(sectionId, text) {
  const section = getSection(sectionId);
  if (section !== null) {
    section.notes = text;
    refreshSummary();
  } else {
    console.log(`Section not found for sectionId: ${sectionId}`);
  }
}
/**
 * sets the markForReview flag for a specified item in a section
 * @param {string} sectionId the section id
 * @param {number} itemIndex the item index
 * @param {boolean} markForReview the mark for review flag
 */
export function setItemMarkForReview(sectionId, itemIndex, markForReview) {
  const item = getSectionItem(sectionId, itemIndex);
  if (item !== null) {
    item.markForReview = markForReview;
  } else {
    console.log(
      `Item not found for sectionId: ${sectionId} with itemIndex: ${itemIndex}`,
    );
  }
}

/**
 * sets the total number of hints viewed for a specified item in a section
 * @param {string} sectionId the section id
 * @param {number} itemIndex the item index
 * @param {number} totalHintsViewed number of hints viewed
 */
export function setItemTotalHintsViewed(
  sectionId,
  itemIndex,
  totalHintsViewed,
) {
  const item = getSectionItem(sectionId, itemIndex);
  if (item !== null) {
    item.totalHintsViewed = totalHintsViewed;
  } else {
    console.log(
      `Item not found for sectionId: ${sectionId} with itemIndex: ${itemIndex}`,
    );
  }
}

/**
 * Adds or replaces state properties in the answer options object
 * @param {object} moduleOption the module answer option object
 * @param {object} stateOption the state for the answer option
 */
function mergeOptionState(moduleOption, stateOption, moduleItem) {
  setAttributesIfExist(
    moduleOption,
    stateOption,
    'optionMode',
    'highlightList',
  );
  if (!isNullOrUndefined(stateOption.optionState)) {
    moduleOption.optionState = stateOption.optionState;
    if (stateOption.optionState === OptionState.Selected) {
      moduleItem.answer = stateOption.letter;
      moduleItem.isAnswered = true;
    }
  }
}
/**
 * Adds or replaces state properties in the question item object
 * @param {object} moduleItem the module question item object
 * @param {object} stateItem the state for the question item
 */
function mergeItemState(moduleItem, stateItem) {
  setAttributesIfExist(
    moduleItem,
    stateItem,
    'markForReview',
    'writingText',
    'totalHintsViewed',
  );
  if (!isNullOrUndefined(stateItem.answerOptions)) {
    let i = 0;
    for (i = 0; i < stateItem.answerOptions.length; i += 1) {
      const stateOption = stateItem.answerOptions[i];
      createArrayIfNecessary(moduleItem, 'answerOptions');
      const moduleOption = getArrayItemByKey(
        moduleItem.answerOptions,
        'letter',
        stateOption.letter,
      );
      mergeOptionState(moduleOption, stateOption, moduleItem);
    }
  }
}
/**
 * Adds or replaces state properties in the section object
 * @param {object} moduleSection the module section object
 * @param {object} stateSection the state for the section
 */
function mergeSectionState(moduleSection, stateSection) {
  setAttributesIfExist(
    moduleSection,
    stateSection,
    'timeRemaining',
    'currentItemIndex',
    'startTime',
    'endTime',
    'isCompleted',
    'lastUpdateTime',
    'notes',
    'elapsedTime',
    'analysisTimeRemaining',
  );
  if (!isNullOrUndefined(stateSection.items)) {
    createArrayIfNecessary(moduleSection, 'items');
    let i = 0;
    for (i = 0; i < stateSection.items.length; i += 1) {
      const stateItem = stateSection.items[i];
      const moduleItem = getArrayItemByKey(
        moduleSection.items,
        'itemNumber',
        stateItem.itemNumber,
      );
      mergeItemState(moduleItem, stateItem);
    }
  }
  if (!isNullOrUndefined(stateSection.stimulus)) {
    let i = 0;
    for (i = 0; i < stateSection.stimulus.length; i += 1) {
      const stateStimulus = stateSection.stimulus[i];
      createArrayIfNecessary(moduleSection, 'stimulus');
      const moduleStimulus = getArrayItemByKey(
        moduleSection.stimulus,
        'stimulusId',
        stateStimulus.stimulusId,
      );
      setAttributeIfExists(moduleStimulus, stateStimulus, 'highlightList');
    }
  }
  if (!isNullOrUndefined(stateSection.stems)) {
    let i = 0;
    for (i = 0; i < stateSection.stems.length; i += 1) {
      const stateStem = stateSection.stems[i];
      createArrayIfNecessary(moduleSection, 'stems');
      const moduleStem = getArrayItemByKey(
        moduleSection.stems,
        'stemId',
        stateStem.stemId,
      );
      setAttributeIfExists(moduleStem, stateStem, 'highlightList');
    }
  }
}
/**
 * Adds or replaces state properties in the module object. Since the
 * state object follows the same format as the module object, we can
 * also use this to merge two state objects.
 *
 * @param {object} targetModule the module object
 * @param {object} state the state for the module
 * @param {bool} doDeepMerge if true, then merge section data
 */
export function mergeModuleState(
  targetModule,
  state,
  doDeepMerge = true,
  referenceModule = null,
) {
  if (!isNullOrUndefined(state)) {
    const { customTime } = state;

    if (!isNullOrUndefined(state.sections) && doDeepMerge) {
      let i = 0;
      if (isNullOrUndefined(targetModule.sections)) {
        targetModule.sections = [];
      }
      for (i = 0; i < state.sections.length; i += 1) {
        const stateSection = state.sections[i];
        const moduleSection = getArrayItemByKey(
          targetModule.sections,
          'formId',
          stateSection.formId,
        );
        mergeSectionState(moduleSection, stateSection);
        let overrideTime = moduleSection?.options?.sectionTime || customTime;
        if (referenceModule) {
          const referenceSection = referenceModule.sections.filter(
            section => stateSection.formId === section.sectionId,
          )[0];
          if (referenceSection && referenceSection?.options?.sectionTime) {
            overrideTime = referenceSection.options.sectionTime;
          }
        }
        if (!isNullOrUndefined(overrideTime)) {
          moduleSection.totalSectionTime = overrideTime * 60 * 1000;

          if (
            isNullOrUndefined(moduleSection.startTime) &&
            !moduleSection.isCompleted
          ) {
            moduleSection.timeRemaining = moduleSection.totalSectionTime;
          }
        }
        if (moduleSection.sectionOrder === 1) {
          moduleSection.breakTimeRemaining = 60000;
        } else if (!isNullOrUndefined(stateSection.breakTimeRemaining)) {
          moduleSection.breakTimeRemaining = Math.max(
            stateSection.breakTimeRemaining,
            60000,
          );
        }
      }
    }

    if (
      doDeepMerge &&
      isNullOrUndefined(state.sections) &&
      !isNullOrUndefined(state.customTime) &&
      !isNullOrUndefined(targetModule) &&
      !isNullOrUndefined(targetModule.sections)
    ) {
      for (let i = 0; i < targetModule.sections.length; i += 1) {
        const moduleSection = targetModule.sections[i];
        if (
          isNullOrUndefined(moduleSection.startTime) &&
          !moduleSection.isCompleted
        ) {
          if (moduleSection?.options?.sectionTime) {
            moduleSection.totalSectionTime =
              moduleSection.options.sectionTime * 60 * 1000;
          } else {
            moduleSection.totalSectionTime = state.customTime * 60 * 1000;
          }
          moduleSection.timeRemaining = moduleSection.totalSectionTime;
        }
      }
    }

    setAttributesIfExist(
      targetModule,
      state,
      'lastUpdateTime',
      'startTime',
      'endTime',
      'isCompleted',
      'moduleType',
      'browser',
      'isFlexPaused',
      'currentPage',
      'flexPausedOn',
    );

    targetModule.examMode = !isNullOrUndefined(state.examMode)
      ? state.examMode
      : ExamMode.SelfPaced;
    targetModule.customTime = !isNullOrUndefined(state.customTime)
      ? state.customTime
      : null;

    if (!isNullOrUndefined(state.isSectionPaused) && state.isSectionPaused) {
      targetModule.isSectionPaused = state.isSectionPaused;
      targetModule.pausedOnPage = state.pausedOnPage;
      targetModule.pausedSectionId = state.pausedSectionId;
      targetModule.pausedQuestionIndex = state.pausedQuestionIndex;
    }
  }
  return targetModule;
}
/**
 * Adds or replaces module pause state properties in the module object. Since the
 * state object follows the same format as the module object, we can
 * also use this to merge two state objects.
 *
 * @param {object} module the module object
 */
export function mergePauseModuleState(module, testInstanceId) {
  const instanceRepo = getInstanceById(testInstanceId);
  if (!isNullOrUndefined(instanceRepo)) {
    const { state } = instanceRepo;

    if (!isNullOrUndefined(state)) {
      if (!isNullOrUndefined(state.isSectionPaused) && state.isSectionPaused) {
        module.isSectionPaused = state.isSectionPaused;
        module.pausedOnPage = state.pausedOnPage;
        module.pausedSectionId = state.pausedSectionId;
        module.pausedQuestionIndex = state.pausedQuestionIndex;
      }
    }
  } else if (module.isFlexExam) {
    module.isSectionPaused = true;
  }

  return module;
}

import { isNullOrUndefined } from 'util';

import * as constants from 'containers/DirectionsPage/constants';
import * as transactionConstants from 'containers/DirectionsPage/transactionConstants';
import * as testSelectConstants from 'containers/TestSelectPage/constants';
import TelemetryItemModel from 'models/telemetryItemModel';
import { config } from '../config';
import { getAPIFullPath } from './configHelper';
import { getUserId } from './userHelper';
import * as logHelper from './logHelper';
import * as auth from '../auth';
import { removeItemFromLocal, storeValueInLocal } from './localStorageHelper';
import { setTimeSpentOnItem } from './persistence/examManagementHelper';

const util = require('util');
export const TELEM_STORAGE_KEY = 'telemetryEvents';
let unloadEventAdded = false;
let isAnswered = false;
let isModuleAllowed = false;
let testInstanceId = null;
let isDuplicateCall = false;
let updateInProgress = false;

let telemetryItemEvent = null;
let answerChangeEvent = null;

const telemetryEvents = {
  userId: null,
  testInstanceId: null,
  events: [],
};

export function getTelemetryEvents() {
  return telemetryEvents;
}

export function saveItemInfo(
  actionType,
  data,
  state,
  module = {},
  itemEvent = new TelemetryItemModel(),
) {
  let flag = true;

  if (
    config.REACT_APP_TELEMETRY_FEATURE_ENABLED.toLowerCase() !== 'true' ||
    !data
  ) {
    return 'Feature Flag is off or missing data';
  }

  if (telemetryItemEvent == null || !telemetryItemEvent.isCurrent) {
    telemetryItemEvent = itemEvent;
  }
  const itemData = data.items[data.currentItemIndex];

  switch (actionType) {
    case constants.CHANGE_OPTION_STATE:
      if (data && data.isCompleted) {
        return '';
      }

      if (!isModuleAllowed) {
        return '';
      }

      // If answer change is the same then don't log
      if (
        answerChangeEvent &&
        answerChangeEvent.details.itemNumber === data.currentItemIndex + 1
      ) {
        if (
          itemData.isAnswered &&
          (answerChangeEvent.details.response === itemData.answer ||
            itemData.answer === null)
        ) {
          return '';
        }
      }

      if (!itemData.isAnswered && !isAnswered) {
        return '';
      }

      answerChangeEvent = {
        eventType: transactionConstants.ITEM_RESPONSE,
        eventTime: getCurrentDateTime(),
        epochEventTime: Date.now(),
        details: {
          sectionNumber: data.sectionOrder,
          itemNumber: data.currentItemIndex + 1,
          elementId: itemData.elementId,
          response: itemData.answer,
        },
      };

      isAnswered = itemData.isAnswered ?? false;
      verifyDuplicateAndAddTelemetryItem(answerChangeEvent);
      answerChangeEvent = null;
      break;
    case constants.CHANGE_SELECTION:
      isAnswered = itemData.isAnswered ?? false;
      if (data && data.isCompleted) {
        return '';
      }

      if (!isModuleAllowed) {
        return '';
      }

      if (!state) {
        return '';
      }

      // If duplicate calls, then check if user is on current item
      isDuplicateCall = checkIfDuplicateCall(data);
      if (isDuplicateCall) {
        return '';
      }

      if (telemetryItemEvent.isCurrent) {
        handleEndOfEvent(data);
      }

      testInstanceId = state.selectedInstanceId;
      setNewItemInfo(data, itemData, testInstanceId);
      break;
    case constants.LOAD_SECTION:
      // Check if anything in localStorage to save
      saveDataFromLocalStorage();

      isAnswered = itemData.isAnswered ?? false;

      if ((data && data.isCompleted) || telemetryItemEvent.isCurrent) {
        return '';
      }

      if (state) {
        const indx = state.instances.findIndex(
          obj => obj.testInstanceId === state.selectedInstanceId,
        );
        const moduleId = String(state.instances[indx].module.moduleId);

        if (config.REACT_APP_TELEMETRY_MODULE_ID_AVAILABILITY.includes('ALL')) {
          flag = false;
        } else if (
          config.REACT_APP_TELEMETRY_MODULE_ID_AVAILABILITY.includes(
            'EVERY_PREP',
          ) &&
          isNullOrUndefined(module?.options?.isRealExamMode)
        ) {
          flag = false;
        } else if (
          config.REACT_APP_TELEMETRY_MODULE_ID_AVAILABILITY.includes(
            'EVERY_EXAM',
          ) &&
          module?.options?.isRealExamMode === true
        ) {
          flag = false;
        } else if (
          config.REACT_APP_TELEMETRY_MODULE_ID_AVAILABILITY.includes(moduleId)
        ) {
          flag = false;
        }

        if (flag) {
          return '';
        }

        isModuleAllowed = true;
      } else {
        return '';
      }

      if (!unloadEventAdded) {
        window.addEventListener('beforeunload', beforeUnloadingExecution);
        unloadEventAdded = true;
      }

      // If duplicate calls, then check if user is on current item
      isDuplicateCall = checkIfDuplicateCall(data);
      if (isDuplicateCall) {
        return '';
      }

      testInstanceId = state.selectedInstanceId;
      setNewItemInfo(data, itemData, testInstanceId);
      break;
    case testSelectConstants.GET_LIBRARY:
      isAnswered = false;

      if (!isModuleAllowed) {
        return '';
      }

      if (telemetryItemEvent.isCurrent) {
        handleEndOfEvent(data);
      }

      window.removeEventListener('beforeunload', beforeUnloadingExecution);
      unloadEventAdded = false;
      isModuleAllowed = false;

      break;
    case constants.SET_PAUSE_SECTION:
      if (!isModuleAllowed) {
        return '';
      }

      if (
        data.isPaused &&
        !isNullOrUndefined(telemetryItemEvent) &&
        telemetryItemEvent.isCurrent
      ) {
        handleEndOfEvent(data);
        isAnswered = false;
      }
      break;
    case constants.UNLOAD_SECTION:
      if (!isModuleAllowed || data?.isCompleted) {
        return '';
      }

      if (
        !data.isPaused &&
        !isNullOrUndefined(telemetryItemEvent) &&
        telemetryItemEvent.isCurrent
      ) {
        handleEndOfEvent(data);

        isAnswered = false;
        isModuleAllowed = false;
      }
      break;
    case constants.SET_SECTION_COMPLETE:
      if (!isModuleAllowed) {
        return '';
      }

      if (
        !isNullOrUndefined(telemetryItemEvent) &&
        telemetryItemEvent.isCurrent
      ) {
        handleEndOfEvent(data);
        isAnswered = false;
      }
      break;
    default:
      return '';
  }

  return actionType;
}

function handleEndOfEvent(data) {
  // update endTime for the event
  telemetryItemEvent.details.endTime = getCurrentDateTime();
  telemetryItemEvent.details.epochEndTime = Date.now();
  telemetryItemEvent.isCurrent = false;

  // add telemetry event to telemetry events array
  verifyDuplicateAndAddTelemetryItem(telemetryItemEvent);

  // calculate total time taken for the question
  if (data) {
    const timeTakenInMS =
      telemetryItemEvent.details.epochEndTime -
      telemetryItemEvent.epochEventTime;
    const totalQuestionTime = Math.round((timeTakenInMS ?? 0) / 1000);

    setTimeSpentOnItem(
      data.formId,
      telemetryItemEvent.details.itemNumber,
      totalQuestionTime,
    );
  }
}

function checkIfDuplicateCall(dataToCheck) {
  if (
    dataToCheck &&
    dataToCheck.currentItemIndex &&
    dataToCheck.currentItemIndex + 1 === telemetryItemEvent.details.itemNumber
  ) {
    return true;
  }

  return false;
}

export function verifyDuplicateAndAddTelemetryItem(telemetryItem) {
  if (!telemetryItem) return;

  const lastEvent = telemetryEvents.events.at(-1);

  if (!lastEvent || lastEvent.epochEventTime !== telemetryItem.epochEventTime) {
    telemetryEvents.events.push(telemetryItem);
  }
}

export function setupTimedSave() {
  setInterval(() => {
    putItemInfo();
  }, config.REACT_APP_TELEMETRY_ITEM_SAVE_INTERVAL);
}

function clearTelemetryItemsFromList(itemsToDelete, reset = false) {
  if (reset) {
    telemetryEvents.events.length = 0;

    telemetryItemEvent = new TelemetryItemModel();
    telemetryItemEvent.eventType = null;
    telemetryItemEvent.eventTime = null;
    telemetryItemEvent.epochEventTime = null;
    telemetryItemEvent.details.sectionNumber = null;
    telemetryItemEvent.details.itemNumber = null;
    telemetryItemEvent.details.elementId = null;
    telemetryItemEvent.details.endTime = null;
    telemetryItemEvent.details.epochEndTime = null;
    telemetryItemEvent.details.response = null;
    telemetryItemEvent.isCurrent = false;
  } else if (itemsToDelete) {
    telemetryEvents.events = telemetryEvents.events.filter(event =>
      itemsToDelete.every(item => item.epochEventTime !== event.epochEventTime),
    );
  }
}

export function saveDataFromLocalStorage() {
  const telemEventsJson = localStorage.getItem(TELEM_STORAGE_KEY);

  if (telemEventsJson) {
    const telemEvents = JSON.parse(telemEventsJson);

    if (telemEvents) {
      telemEvents.events.forEach(event => {
        verifyDuplicateAndAddTelemetryItem(event);
      });
      telemetryEvents.userId = telemEvents.userId;
      telemetryEvents.testInstanceId = telemEvents.testInstanceId;
    }
  }
}

function setNewItemInfo(data, itemData, instanceId) {
  telemetryEvents.userId = getUserId();
  telemetryEvents.testInstanceId = instanceId;

  telemetryItemEvent = new TelemetryItemModel();
  telemetryItemEvent.eventType = transactionConstants.ITEM_NAVIGATION;
  telemetryItemEvent.eventTime = getCurrentDateTime();
  telemetryItemEvent.epochEventTime = Date.now();
  telemetryItemEvent.details.sectionNumber = data.sectionOrder;
  telemetryItemEvent.details.itemNumber = data.currentItemIndex + 1;
  telemetryItemEvent.details.elementId = itemData.elementId;
  telemetryItemEvent.details.endTime = null;
  telemetryItemEvent.details.epochEndTime = null;
  telemetryItemEvent.details.response = null;
  telemetryItemEvent.isCurrent = true;
}

/* eslint-disable consistent-return */
export async function putItemInfo() {
  if (config.REACT_APP_TELEMETRY_FEATURE_ENABLED.toLowerCase() !== 'true') {
    return false;
  }

  if (telemetryEvents && telemetryEvents.events.length > 0) {
    const token = await auth.getAccessToken();

    const apiEndURL = `${util.format(
      config.REACT_APP_PUT_ITEM_INFO_URL,
      telemetryEvents.userId,
    )}`;

    const eventsToSend = global.structuredClone(telemetryEvents);

    const fullAPIURL = getAPIFullPath(apiEndURL);
    const settings = {
      keepalive: true,
      method: 'POST',
      body: JSON.stringify(eventsToSend),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    };

    if (!updateInProgress) {
      try {
        updateInProgress = true;

        const response = await fetch(fullAPIURL, settings);

        updateInProgress = false;
        if (!response.ok) {
          if (response.statusText) {
            logHelper.log(response.statusText);
          }
          return false;
        }

        clearTelemetryItemsFromList([...eventsToSend.events]);
        removeItemFromLocal(TELEM_STORAGE_KEY);
        return true;
      } catch (e) {
        updateInProgress = false;
        logHelper.log(e.message);
        return false;
      }
    }
  }
  return false;
}

function getCurrentDateTime() {
  const today = new Date();
  const date = `${today.getMonth() +
    1}/${today.getDate()}/${today.getFullYear()}`;
  const minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
  const seconds = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();

  const time = `${today.getHours()}:${minutes}:${seconds}`;
  const dateTime = `${date} ${time}`;

  return dateTime;
}

async function beforeUnloadingExecution() {
  if (config.REACT_APP_TELEMETRY_FEATURE_ENABLED.toLowerCase() !== 'true') {
    return;
  }

  if (telemetryItemEvent.isCurrent) {
    handleEndOfEvent(telemetryItemEvent);
  }

  // Save to localStorage

  if (telemetryEvents.events.length > 0) {
    storeValueInLocal(TELEM_STORAGE_KEY, JSON.stringify(telemetryEvents));
  } else if (putItemInfo) {
    // call save
    await putItemInfo();
  }

  const time = Date.now();
  while (Date.now() - time < 1200) {
    // Leaving empty
  }
}
